* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.heading {
  margin: 20px 0 20px 1.5vw;
}

/* campaign dashboard */
.subheading {
  margin: 20px 0;
}
.main-container {
  width: 100%;
  display: flex;
  gap: 25px;
  margin: 2% 0;
  flex-wrap: wrap;
}
.grid-container {
  width: 55%;
}
.form-container {
  width: 40%;
}
.error-message {
  background-color: #ff9999;
  padding: 10px;
  border-radius: 10px;
  color: #ffffff;
}
.info-message {
  background-color: #66ccff;
  padding: 10px;
  border-radius: 10px;
}
.dashboard-msg {
  width: 50%;
}
/* create-campaign page */

/* Container and Title */
.create-campaign-container {
  width: 50%; /* Increased width */
  max-width: 1400px; /* Adjust to ensure it doesn’t exceed the screen on larger displays */
  height: auto;
  margin: 2% auto;
  padding: 15px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.create-campaign-title {
  font-weight: bold;
  font-size: 1.7em;
  color: #333;
  margin-bottom: 1%;
}

/* Info Message */
.info-message {
  background-color: #e6f0fa;
  color: #333;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  font-size: 1em;
  text-align: center;
}

/* Form Fields */
.campaign-form {
  margin-bottom: 2%;
}
.campaign-form .form-group-custom {
  margin-bottom: 2%;
  width: auto;
}

.campaign-form .form-control {
  border: 1px solid #ced4da;
  border-radius: 5px;
  padding: 5px;
}

.campaign-form .form-control::placeholder {
  color: #adb5bd;
  font-size: 0.9em;
}
.heading-color {
  color: #2f539b;
}

/* Required Asterisk */
.custom-label {
  margin-bottom: 3%;
  font-size: 14px;
}
/* .custom-label::after {
  content: " *";
  color: #d9534f;
  font-weight: bold;
} */
.create-print-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}
.create-print-msgs {
  margin: 2% auto;
  width: 52%;
  min-width: 300px;
}

/* Button */
.create-campaign-button {
  width: 200px;
  padding: 8px;
  font-size: 1.2em;
  border-radius: 5px;
  background-color: #343a40;
  color: #fff;
  border: none;
  transition: background-color 0.3s ease;
  margin-top: 2%;
}

.create-main-container {
  width: 95%;
  margin: 4% 0 2% 3%;
}
.create-grid-form {
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: 25px;
  width: 100%;
  transition: all 0.3s ease-in-out;
}
.grid-centered .create-grid-container {
  width: 53%;
}
.grid-to-left {
  justify-content: flex-start;
}
.grid-to-left .create-grid-container {
  width: 50%;
}

.create-form-container {
  margin: 0;
  width: 50%;
  transition: opacity 0.3s ease-in-out;
}

.create-form-container.hidden {
  opacity: 0;
  pointer-events: none;
}
@media screen and (max-width: 768px) {
  .create-campaign-title {
    font-size: 1.4em;
    margin-bottom: 4%;
  }
  .create-grid-form {
    flex-direction: column;
    align-items: center;
  }
  .create-campaign-container {
    width: 95%;
  }
  .campaign-form .form-group-custom {
    margin-bottom: 1%;
    width: 100%;
  }
  .create-grid-container {
    width: 100% !important;
  }
  .create-form-container {
    width: 100%;
  }
}
/* sidebar component */
.linktag {
  text-decoration: none;
  color: #fff;
  padding: 10px 15px;
  display: block;
  transition: background-color 0.3s;
  width: 90%;
  font-size: 1em;
}

.linktag:hover {
  background-color: #f0f0f0;
  color: #007bff;
  border-radius: 10px;
}
.linktag.active {
  background-color: white;
  color: black;
  font-weight: bold;
  border-radius: 10px;
}
ul {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
ul > li {
  font-size: 10px;
}

/* Style for the Hamburger Icon */
.hamburger-icon {
  cursor: pointer;
  position: absolute;
  top: 20px;
  left: 20px;
  color: #333;
}

.close-icon {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}

.list-container {
  gap: 10px;
}
.list-container h5 {
  color: #fff;
  margin: 6%;
}

.sidebar-container {
  margin: 30% 0;
}

.logo-container {
  margin: 30px 10px;
  display: flex;
  justify-content: center; /* Center the logo horizontally */
  align-items: center; /* Center the logo vertically if necessary */
}

.logo-container img {
  max-width: 100%; /* Makes the image responsive */
  height: auto; /* Maintains the aspect ratio */
  object-fit: contain; /* Ensures the image fits well within its container */
}
/* upload contacts page */
.upload-contacts-container {
  margin: 2%;
  margin-left: 10%;
  overflow: hidden;
  padding: 10px;
}
.upload-box {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px;
  background-color: white;
  border-radius: 15px;
}
.upload-browse-span {
  color: black;
  font-weight: bold;
}
.upload-drag-span {
  margin-left: 8px;
}
.main-heading {
  font-weight: bold;
  font-size: 1.7em;
  color: #333;
  margin-bottom: 1%;
}
.subheading-1 {
  margin-bottom: 1.5%;
  color: #2f539b;
}

.dashboard-container {
  margin: 3%;
}
.dashboard-msg {
  font-size: 27px;
  color: #d9534f;
}
.container-csv {
  width: 100%;
}

.file-upload-container {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #fff;
  width: 400px;
  max-width: 500px;
  margin: auto;
  margin-bottom: 3%;
}

.upload-area {
  width: 100%;
  text-align: center;
  border-radius: 10px;
  padding: 20px;
  background-color: #f0f0f0;
  margin-bottom: 20px;
  position: relative;
  display: inline-block;
  box-sizing: border-box;
}

.upload-label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  font-size: 16px;
  color: #666;
  cursor: pointer;
}

.upload-label span {
  font-weight: bold;
}

.file-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.file-list {
  width: 100%;
  margin-bottom: 1%;
}

.file-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  background-color: #fff;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 10px;
}

.file-item span {
  font-size: 18px;
  flex-grow: 1;
  margin-right: 10px;
  white-space: nowrap; /* Prevent text wrapping */
  overflow: hidden; /* Hide overflowing text */
  text-overflow: ellipsis; /* Add ellipsis for overflowing text */
}

.icon {
  cursor: pointer;
}

.success {
  color: green;
  font-size: 22px;
}

.delete {
  background-color: lightgray; /* Adds light gray background */
  border-radius: 50%; /* Makes the background circular */
  padding: 10px; /* Adds more spacing for a larger circle */
  font-size: 20px; /* Ensures the icon size stays prominent */
  color: black; /* Keeps the icon color red */
  cursor: pointer; /* Adds a pointer cursor for better UX */
  display: inline-flex; /* Ensures proper alignment for icons */
  align-items: center; /* Centers the icon vertically */
  justify-content: center; /* Centers the icon horizontally */
  width: 35px; /* Sets a fixed width for the circle */
  height: 35px; /* Sets a fixed height for the circle */
}

.upload-button {
  width: 50%;
  margin: 3% auto;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
}
@media (max-width: 768px) {
  .file-upload-container {
    padding: 12px;
  }

  .upload-area {
    padding: 12px;
  }

  .upload-browse {
    padding: 8px;
    font-size: 0.9rem;
  }

  .upload-heading {
    font-size: 1rem;
  }

  .file-item {
    padding: 8px;
    font-size: 0.9rem;
  }

  .upload-button {
    width: 90%;
  }
}
@media (max-width: 498px) {
  .file-upload-container {
    width: 100%;
  }
  .upload-browse {
    flex-direction: column;
    gap: 4px;
  }

  .upload-heading {
    font-size: 0.9rem;
    text-align: center;
  }

  .upload-button {
    font-size: 0.9rem;
    padding: 8px;
  }
}

@media screen and (max-width: 768px) {
  .main-container {
    flex-direction: column;
  }
  .grid-container,
  .form-container {
    width: 100%;
  }
  .main-heading {
    font-size: 1.4em;
  }
  .subheading,
  .subheading-1 {
    font-size: 1.1em;
  }
  .subheading-1 {
    margin-top: 6%;
    margin-bottom: 6%;
  }
  .upload-para {
    font-size: 0.8em;
  }
}
.upload-contacts-right {
  display: flex;
  flex-direction: column;
}
/* proof template page*/
.proof-subheading {
  margin-bottom: 2%;
  color: #2f539b;
}

.proof-note {
  margin: 1% 0;
}
.proof-note-head {
  font-weight: bold;
}
.proof-form-container {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  margin-top: 3%;
}
.generate-button {
  margin: 1% 0;
}
.modal-heading {
  display: flex;
  justify-content: center;
}
.modal-form {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  margin: 10px;
}
.modal-form-field {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal-button {
  margin-top: 2%;
}
.modal-legends {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

@media screeen and (max-width: 400px) {
  .modal-legends {
    display: flex;
  }
}

.print-container {
  width: 52%; /* Increased width */
  min-width: 300px;
  max-width: 1400px; /* Adjust to ensure it doesn’t exceed the screen on larger displays */
  height: 70%;
  margin: 2% auto;
  padding: 35px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  flex-wrap: wrap;
}

.responsive-button {
  margin: 5% 2% 2% 0;
  width: 40%;
  font-size: 1em;
}
.create-print-msg {
  margin: 2% 1%;
  width: 100%;
  min-width: 300px;
}
.print-content-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.print-content-form {
  width: auto;
  margin-top: 5%;
  margin-bottom: 2%;
}

/* Make the button responsive on smaller screens */
@media (max-width: 1428px) {
  .responsive-button {
    width: 270px; /* Full width for smaller devices */
    font-size: 0.9em; /* Slightly smaller text size */
    margin: 25px 0; /* Adjust margins for spacing */
  }
  .print-container {
    height: 65%;
    width: 90%;
  }
}
@media (max-width: 768px) {
  .responsive-button {
    width: 100%; /* Full width for smaller devices */
    font-size: 0.9em; /* Slightly smaller text size */
    margin: 25px 0; /* Adjust margins for spacing */
  }
  .print-container {
    height: 45%;
    width: 90%;
  }
}

@media (max-width: 480px) {
  .responsive-button {
    font-size: 0.8em; /* Further reduce text size on very small screens */
    padding: 10px 15px; /* Adjust padding for better fit */
  }
}

.home-container {
  margin: 6%;
  /* margin-left: 17%;
  margin-right: 0; */
}
.home-heading {
  font-weight: bold;
  margin-bottom: 2%;
}
.home-subheading {
  margin-bottom: 2%;
}
.home-list {
  list-style-type: disc;
}
.home-list li {
  font-size: medium;
}
.user-container {
  margin: 7%;
}
.proof-form-container .form-group-custom {
  margin-bottom: 3%;
}
.existing-grid {
  width: auto;
  position: relative;
  margin: 3%;
}
.user-heading {
  font-weight: bold;
  margin-bottom: 2%;
}
